@use 'media';

/*
  Button styling in accordance with Q Reusability Template.

  Usage instructions:

  Primary button:
    <button mat-raised-button color="primary">Button</button>
  
  Secondary button:
    <button mat-raised-button color="accent">Button</button>

  Tertiary button:
    <button mat-raised-button>Button</button>

  Outlined button:
    <button mat-stroked-button>Button</button>

  Text only button:
    <button mat-button>Button</button>

  For small variations of buttons, just add btn-small class to any of the above variations.

*/

@mixin base {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 52px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0 24px;
  white-space: pre-wrap;
  line-height: 20px;

  @media screen and (min-width: media.$tablet-min-width) {
    min-height: 56px;
    line-height: 24px;
  }

  &.btn-small {
    min-height: 40px;
    @media screen and (min-width: media.$tablet-min-width) {
      min-height: 48px;
    }
  }

  .mat-progress-spinner {
    margin-left: auto;
    margin-right: auto;
  }
}

.mat-button-base.mat-button {
  @include base();
}

.mat-button-base.mat-raised-button,
.mat-flat-button {
  @include base();

  &:hover:not(.mat-warn):not(.mat-button-disabled) {
    background-color: var(--btn-tertiary-hover-bg);

    &.mat-primary {
      background-color: var(--btn-primary-hover-bg);
      color: var(--btn-primary-hover-label);
    }

    &.mat-accent {
      background-color: var(--btn-secondary-hover-bg);
      color: var(--btn-secondary-hover-label);
    }

    &.mat-warn {
      background-color: initial;
    }
  }

  &.mat-accent {
    color: var(--btn-secondary-default-label);
  }
}

a,
button {
  &.mat-button-base.mat-stroked-button {
    @include base();
    border-color: var(--btn-outlined-default-border) !important; // TODO: Try increased specificity?

    &:hover {
      background-color: var(--btn-outlined-hover-bg);
    }
  }
}

.mat-button-base.mat-flat-button {
  @include base();
}
