.no-padding-container .mat-dialog-container,
.no-padding-container .cdk-dialog-container {
  padding: 0;
}

mat-dialog-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &[mat-dialog-title] {
      font-weight: normal;
      margin-bottom: var(--spacing-4x);
    }
  }
}
