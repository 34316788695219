.q-mat-menu {
  &.top-margin {
    margin-top: 16px;
  }

  button {
    display: flex;
    align-items: center;
  }
}
