:root {
  --brand-primary-default: #00ff00;
  --brand-primary-dark: #008000;
  --brand-primary-light: #ccffcc;
  --text-primary: #191919;
  --text-secondary: #858585;
  --text-tertiary: #ffffff;
  --section-primary-bg: #f0f0f0;
  --section-tertiary-bg: #292929;
  --background: white;
  --body-secondary-bg: #f6f6f6;
  --error-light: #ff6b6b;
  --error-dark: #8f0a00;
  --grey: #858585;

  // Buttons
  --btn-primary-hover-bg: #008000;
  --btn-primary-hover-label: #ffffff;
  --btn-secondary-hover-bg: #191919;
  --btn-secondary-default-label: var(--brand-primary-default);
  --btn-secondary-hover-label: var(--brand-primary-light);
  --btn-tertiary-hover-bg: var(--brand-primary-light);
  --btn-outlined-default-border: #292929;
  --btn-outlined-hover-bg: var(--brand-primary-light);

  // Table
  --table-body-secondary-bg: #f6f6f6;

  // Breadcrumb
  --breadcrumbs-primary-inactive: #949494;
  --breadcrumbs-primary-active: #191919;
  --breadcrumbs-primary-icon: #949494;

  // profile tabs
  --profile-primary-default: #e0e0e0;

  // Navbar
  --navbar-light-default-label: #ffffff;
  --navbar-light-active-label: var(--brand-primary-default);
  --navbar-light-active-decoration: #858585;

  // Side menu
  --gray-light-200: #e0e0e0;
  --side-menu-light-active-decoration: #e0e0e0;

  // Alert
  --alert-success-light: var(--brand-primary-default);
  --alert-error-light: #ff6b6b;
  --alert-warn-light: #ffb26b;
  --alert-information-light: #00ffff;

  // List item
  --hover-background-color: #f0f0f0;
}
