:root {
  --mat-icon-normal-size: 24px;
  --mat-icon-big-size: 48px;
}

mat-icon[color='primary'] {
  &[variant='light'] {
    color: var(--brand-primary-light) !important;
  }
  &[variant='dark'] {
    color: var(--brand-primary-dark) !important;
  }
}

mat-icon.big-icon {
  width: var(--mat-icon-big-size);
  height: var(--mat-icon-big-size);
  font-size: var(--mat-icon-big-size);
}
