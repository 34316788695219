@use 'media';

$base-unit: 8px;

:root {
    --main-container-outer-padding: 24px;
    --in-group-element-spacing: 16px;

    @media screen and (min-width: media.$tablet-min-width) {
        --main-container-outer-padding: 80px;
        --in-group-element-spacing: 24px;
    }

    @media screen and (min-width: media.$desktop-min-width) {
        --main-container-outer-padding: 80px;
        --in-group-element-spacing: 32px;
    }


    @for $i from 1 through 12 {
      --spacing-#{$i}x: #{$base-unit * $i};
    }
}


// Generator of margins and paddings
$incrementor: 8;
$up-until: 128;

@for $i from 0 through $up-until {
  .margin-all-#{$i} {
    margin: #{$i}px !important;
  }
  .margin-top-#{$i} {
    margin-top: #{$i}px !important;
  }
  .margin-bottom-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .margin-left-#{$i} {
    margin-left: #{$i}px !important;
  }
  .margin-right-#{$i} {
    margin-right: #{$i}px !important; 
  }

  .padding-all-#{$i} {
    padding: #{$i}px !important;
  }
  .padding-top-#{$i} {
    padding-top: #{$i}px !important;
  }
  .padding-bottom-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .padding-left-#{$i} {
    padding-left: #{$i}px !important;
  }
  .padding-right-#{$i} {
    padding-right: #{$i}px !important;
  }
  $i: $i + $incrementor;
}


// another spacing
$spacing-positions: 'top', 'right', 'bottom', 'left';
$spacing-types: 'margin', 'padding';

@each $spacing-type in $spacing-types {
  @for $i from 1 through 12 {
    .#{$spacing-type}-#{$i}x {
      #{$spacing-type}: $base-unit * $i !important;
    }
  }

  @each $position in $spacing-positions {
    @for $i from 1 through 12 {
      .#{$spacing-type}-#{$position}-#{$i}x {
        #{$spacing-type}-#{$position}: $base-unit * $i !important;
      }
    }
  }
}