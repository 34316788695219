@use 'sass:map';
@use '@angular/material' as mat;
@use 'media';

@mixin theme($theme) {
  .alert-container {
    border-radius: 4px;
    color: var(--text-primary);

    &.success {
      background-color: var(--alert-success-light);
    }

    &.error {
      background-color: var(--alert-error-light);
      color: var(--text-tertiary);
    }

    &.warn {
      background-color: var(--alert-warn-light);
    }

    &.info {
      background-color: var(--alert-information-light);
    }
  }

  .full-width {
    width: 100%;
    padding-top: 24px;
    justify-content: center;
    @include media.responsive-container-padding();

    @media screen and (min-width: media.$tablet-min-width) {
      padding-top: 32px;
    }

    @media screen and (min-width: media.$desktop-min-width) {
      padding-top: 40px;
    }
  }
}
