mat-radio-group {
  display: inline-flex;

  mat-radio-button {
    margin: 8px;
  }
}

.mat-radio-group.ng-invalid.ng-touched {
  border: 2px solid var(--error-light);
  border-radius: var(--form-control-border-radius);
}
.mat-radio-group.ng-invalid.ng-touched .mat-radio-outer-circle {
  border-color: var(--error-light);
}

.radio-group-error {
  color: var(--error-light);
  font-size: 75%;
  padding-left: var(--spacing-2x);
}
