// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'spacing';
@use 'fonts';
@use 'light-theme';
@use 'media';
@use 'colors';
@use 'defaults';
@use 'utils';
@use 'table';
// material overrides
@use 'button';
@use 'menu';
@use 'mat-icon';
@use 'mat-radio-group';
@use 'mat-dialog';
@use 'mat-list';
@use 'mat-checkbox';

@use './app/profile/components/profile-theme' as profile;
@use './app/shared/components/alert/alert-theme.scss' as alert;

@include mat.core(fonts.$typographyAll);
@include fonts.apply-custom-typography-classes();

.light-theme {
  @include mat.all-component-themes(light-theme.$angular-skeleton);
  // Theming our own components
  @include profile.typography(fonts.$typographyAll);
  @include alert.theme(light-theme.$angular-skeleton);
}

html,
body {
  height: 100%;

  header {
    max-height: 144px;
  }
}

body {
  margin: 0;
  display: flex; /* Useful so that we can just fill the whole space with flex-grow on app-root component */
  -webkit-font-smoothing: antialiased;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4,
.mat-typography h5,
.mat-typography h6,
.mat-typography p {
  margin: 0px; /* Overriding bottom margin because we are using standard element spacing as defined in reusability template. */
}
