table {
  border-spacing: 0px;
  tr {
    text-align: left;

    td,
    th {
      padding: 14px 16px;
    }
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: var(--body-secondary-bg);
    }

    &:hover {
      background-color: var(--body-secondary-bg);
    }
  }
}
