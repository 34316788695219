@use '@angular/material' as mat;
@use 'media';
@use 'sass:map';

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url('../assets/fonts/RubikRegular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url('../assets/fonts/RubikSemiBold.ttf') format('truetype');
  font-weight: 600;
}

:root {
  --font-primary: Rubik, sans-serif;
  --font-secondary: serif;

  // Defining mobile font sizes here.
  --font-base-size: 14px;
  --font-h1-size: 44px;
  --font-h2-size: 32px;
  --font-h3-size: 24px;
  --font-h4-size: 18px;
  --font-h5-size: 14px;
  --font-h6-size: 12px;
  --font-nav-item-size: 16px;
  --font-paragraph-intro-size: 24px;
  --font-paragraph-big-size: 18px;
  --font-paragraph-small-size: 12px;
  --font-link-size: 14px;
  --font-button-size: 14px;
  --font-uppercase-label-size: 12px;

  //  for text-align

  --text-left: left;

  @media screen and (min-width: media.$tablet-min-width) {
    --font-base-size: 16px;
    --font-h1-size: 50px;
    --font-h2-size: 38px;
    --font-h3-size: 22px;
    --font-h4-size: 16px;
    --font-h5-size: 16px;
    --font-h6-size: 12px;
    --font-nav-item-size: 16px;
    --font-paragraph-intro-size: 28px;
    --font-paragraph-big-size: 22px;
    --font-paragraph-small-size: 12px;
    --font-link-size: 16px;
    --font-button-size: 16px;
    --font-uppercase-label-size: 12px;
  }

  @media screen and (min-width: media.$desktop-min-width) {
    --font-base-size: 16px;
    --font-h1-size: 64px;
    --font-h2-size: 45px;
    --font-h3-size: 36px;
    --font-h4-size: 24px;
    --font-h5-size: 16px;
    --font-h6-size: 12px;
    --font-nav-item-size: 16px;
    --font-paragraph-intro-size: 36px;
    --font-paragraph-big-size: 24px;
    --font-paragraph-small-size: 12px;
    --font-link-size: 16px;
    --font-button-size: 16px;
    --font-uppercase-label-size: 12px;
  }
}

// Defining typography, see this guide for more info -> https://material.angular.io/guide/typography
$typography: mat.define-typography-config(
  $font-family: var(--font-primary),
  $headline: mat.define-typography-level(var(--font-h1-size), calc(var(--font-h1-size) * 1.25), 600),
  /* <h1> or .mat-h1 or .mat-headline */ $title:
    mat.define-typography-level(var(--font-h2-size), calc(var(--font-h2-size) * 1.25), 600),
  /* <h2> or .mat-h2 or .mat-title */ $subheading-2:
    mat.define-typography-level(var(--font-h3-size), calc(var(--font-h3-size) * 1.25), 600),
  /* <h3> or .mat-h3 or .mat-subheading-2 */ $subheading-1:
    mat.define-typography-level(var(--font-h4-size), calc(var(--font-h4-size) * 1.25), 600),
  /* <h4> or .mat-h4 or .mat-subheading-1 */ $body-2:
    mat.define-typography-level(var(--font-base-size), calc(var(--font-base-size) * 1.5), 600),
  /* .mat-body-strong or .mat-body-2*/ $body-1:
    mat.define-typography-level(var(--font-base-size), calc(var(--font-base-size) * 1.5), 400),
  /* General body text, default for <p> and everything else */ $button:
    mat.define-typography-level(var(--font-button-size), calc(var(--font-button-size) * 1.5), 600),
  /* Used on material buttons*/
);

// Additional typography levels that are not part of Angular Material Typography.
$typographyCustom: (
  paragraph-intro:
    mat.define-typography-level(
      $font-family: var(--font-primary),
      $font-weight: 400,
      $font-size: var(--font-paragraph-intro-size),
      $line-height: calc(var(--font-paragraph-intro-size) * 1.5),
    ),
  paragraph-big:
    mat.define-typography-level(
      $font-family: var(--font-primary),
      $font-weight: 400,
      $font-size: var(--font-paragraph-big-size),
      $line-height: calc(var(--font-paragraph-big-size) * 1.5),
    ),
  paragraph-small:
    mat.define-typography-level(
      $font-family: var(--font-primary),
      $font-weight: 400,
      $font-size: var(--font-paragraph-small-size),
      $line-height: calc(var(--font-paragraph-small-size) * 1.5),
    ),
  nav-item:
    mat.define-typography-level(
      $font-family: var(--font-primary),
      $font-weight: 400,
      $font-size: var(--font-nav-item-size),
      $line-height: calc(var(--font-nav-item-size) * 1.5),
    ),
  uppercase-label:
    mat.define-typography-level(
      $font-family: var(--font-primary),
      $font-weight: 600,
      $font-size: var(--font-uppercase-label-size),
      $line-height: calc(var(--font-uppercase-label-size) * 1.5),
    ),
);

// Merge of angular defined typography levels and custom typography levels.
$typographyAll: map.merge($typography, $typographyCustom);

/*
    Mixin that generates css classes for each custom typography level
    defined in $typographyCustom. Goes through map and generates
    new class based on each key.
*/
@mixin apply-custom-typography-classes {
  @each $key, $level in $typographyCustom {
    [#{$key}],
    .#{$key} {
      @include mat.typography-level($typographyCustom, #{$key});
    }
  }

  /* Overriding material styles for h5 and h6 because they are calculated as percentages of base size.*/
  .mat-h5,
  .mat-typography .mat-h5,
  .mat-typography h5 {
    font: 600 var(--font-h5-size) / calc(var(--font-h5-size) * 1.5) var(--font-primary);
    color: var(--text-primary);
  }

  .mat-h6,
  .mat-typography .mat-h6,
  .mat-typography h6 {
    font: 600 var(--font-h6-size) / calc(var(--font-h6-size) * 1.5) var(--font-primary);
    color: var(--text-primary);
  }

  // Custom styles, need additional styling not allowed by mat.define-typography-level;
  a,
  .link {
    font: 400 var(--font-link-size) / calc(var(--font-link-size) * 1.5) var(--font-primary);
    text-decoration: underline;
    color: var(--text-primary);
    text-decoration-color: rgba(
      25,
      25,
      25,
      0.4
    ); /* Not defined in Q Reusability Template, copied color from React Skeleton */

    &:not(.mat-menu-item) {
      letter-spacing: 2px;
    }

    &:hover {
      text-decoration-color: var(--text-primary);
    }
  }

  .uppercase-label {
    text-transform: uppercase;
    color: var(--text-primary);
  }
}
